export default {
  computed: {
    userId() {
      return this.user.id;
    },
    avatar() {
      return this.user.userInfo.avatarUrl;
    },
    description() {
      return this.user.userInfo.description;
    },
    banner() {
      return this.user.userInfo.bannerUrl;
    },
    name() {
      return this.user.userInfo.name || "";
    },
    surname() {
      return this.user.userInfo.surname || "";
    },
    fullName() {
      return `${this.name} ${this.surname}`;
    },
    bio() {
      return this.user.userInfo.description;
    },
    nickname() {
      return this.user.nickname;
    },
    address() {
      return this.user.address;
    },
    userCreated() {
      return this.user.created;
    },
  },
};
