<template>
  <label class="checkbox">
    <input
      type="checkbox"
      :value="checkboxName"
      @change="change"
      v-model="value"
      :disabled="loading"
    />
    <span class="cell"></span>
    <span class="text">{{ capitalizeFirstLetter(checkboxName) }}</span>
  </label>
</template>

<script>
import capitalizeFirstLetterMixin from "@/mixins/capitalizeFirstLetterMixin";

export default {
  mixins: [capitalizeFirstLetterMixin],
  name: "FilterMainCheckBox",
  data: () => ({
    value: false,
  }),
  props: {
    checkboxName: {
      type: String,
      required: true,
    },
    loading: {
      required: false,
      default: () => false,
    },
  },
  methods: {
    reset() {
      this.value = false;
    },
    change() {
      this.$emit("change", {
        property: this.checkboxName,
        value: this.value,
      });
    },
  },
};
</script>
