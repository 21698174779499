<template>
  <router-link :to="profileLink(userId)" class="peri-tile">
    <span class="peri-cover">
      <img v-if="banner" :src="banner" />
    </span>
    <span class="peri-userpic">
      <span>
        <img
          v-if="address !== getAddress"
          v-lazy="appointAvatar(avatar, userId)"
        />
        <img v-else v-lazy="getAvatar" />
      </span>
    </span>
    <span class="peri-name" v-if="fullName">{{ truncate(fullName, 20) }}</span>
    <span class="peri-username" v-if="nickname">@{{ nickname }}</span>
    <span class="peri-username mono" v-else>{{ truncate(address, 8) }}</span>
    <span class="peri-about" v-if="bio">{{ truncate(bio, 80) }}</span>
  </router-link>
</template>

<script>
// import UsernameText from "./UsernameText";
import truncateMixin from "@/mixins/truncateMixin";
import appointAvatarMixin from "@/mixins/appointAvatarMixin";
import userInfoMixin from "@/mixins/userInfoMixin";
import { mapGetters } from "vuex";
import useLinkMixin from "@/mixins/useLinkMixin";

export default {
  name: "UserCard",
  mixins: [truncateMixin, appointAvatarMixin, userInfoMixin, useLinkMixin],
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      getAddress: "user/getAddress",
      getAvatar: "user/getAvatar",
    }),
  },
  components: {
    // UsernameText,
  },
};
</script>
