<template>
  <div>
    <PageIntro
      title="Creators"
      :show-sort-filter="true"
      :sort-items="sortItems"
      @setSort="setSort"
    />

    <div id="stack" class="section">
      <div class="wrapper">
        <div class="filters-trigger" @click="isActive = !isActive">Filters</div>

        <div class="filters" :class="{ active: isActive }">
          <div class="svg close" @click="isActive = false">
            <svg><use xlink:href="#svg-close" /></svg>
          </div>

          <div class="filters-wrapper">
            <h2>Filters</h2>

            <div class="filter-item">
              <div class="fel">
                <div class="fel-label">Type</div>
                <FilterMainCheckBox
                  v-for="(item, index) in types"
                  :key="index"
                  :checkbox-name="item"
                  @change="reviewFilterTypes"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="tiles">
          <div class="flex">
            <UserCard
              v-for="(item, index) in items"
              :key="index"
              :user="item"
            />
          </div>
          <ScrollLoader
            :loader-method="getCreators"
            :loader-disable="disableLoading"
          >
            <img
              width="200"
              height="200"
              src="@/assets/img/preloader.svg"
            />
          </ScrollLoader>
          <NoRecordsFound v-if="!items.length && !loading" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageIntro from "../components/common/PageIntro";
import UserCard from "../components/common/UserCard";
import api from "@/api/api";
import FilterMainCheckBox from "@/components/filters/filtersParts/FilterMainCheckbox";
import resetPrevLoadingMixin from "@/mixins/resetPrevLoadingMixin";
import NoRecordsFound from "@/components/common/NoRecordsFound";

export default {
  name: "Creators",
  mixins: [resetPrevLoadingMixin],
  data: () => ({
    isActive: false,
    types: ["creator", "collector"],
    selectedTypes: [],
    items: [],
    page: 0,
    size: 9,
    disableLoading: false,
    total: 0,
    loading: false,
    sort: "user.created,desc",
    sortItems: [
      {
        text: "Date minted to oldest",
        value: "user.created,desc",
      },
      {
        text: "Date minted to newest",
        value: "user.created,asc",
      },
    ],
  }),
  methods: {
    setSort(val) {
      this.sort = val;
      this.resetPrevLoading();
    },
    reviewFilterTypes(val) {
      if (val.value) {
        this.selectedTypes.push(val.property.toUpperCase());
      } else {
        this.selectedTypes = this.selectedTypes.filter(
          (i) => i !== val.property.toUpperCase()
        );
      }
      this.resetPrevLoading();
    },
    resetPrevLoading() {
      this.disableLoading = false;
      this.page = 0;
      this.items = [];
    },
    async getCreators() {
      try {
        this.loading = true;
        const query = {
          page: this.page++,
          size: this.size,
          sort: this.sort,
        };
        const body = {
          statuses: this.selectedTypes,
        };
        const response = await api.getCreators({ query, body });
        this.items = [...this.items, ...response.content];
        this.disableLoading = response.content.length < this.size;
        this.total = response.totalElements;
      } catch {
        this.disableLoading = true;
      } finally {
        this.loading = false;
      }
    },
  },
  components: {
    NoRecordsFound,
    FilterMainCheckBox,
    PageIntro,
    UserCard,
  },
};
</script>
